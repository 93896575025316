import { Input, Modal, Radio, Result, Spin, Steps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaCheckCircle,
} from "react-icons/fa";
import parse from "html-react-parser";
import _ from "lodash";

export default function Quiz() {
  const [data, setData] = useState(null);
  const [answer, setAnswer] = useState([]);
  const [stepCurrent, setStepCurrent] = useState(0);
  const [qid, setQid] = useState([]);
  const [finished, setFinished] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [counter, setCounter] = useState("02:00:00");
  const [score, setScore] = useState(null);

  var currentid = "",
    currentScore = null;
  let startDate = new Date("Mar 18, 2022 11:33:00").getTime();
  let countDownDate = new Date("Mar 18, 2022 13:33:00").getTime();

  // let x = setInterval(() => {
  //     if (window.location.href.includes('quiz')) {
  //         // Get today's date and time
  //         var now = new Date().getTime();

  //         if (startDate - now < 0) {
  //             // Find the distance between now and the count down date
  //             var distance = countDownDate - now;

  //             // Time calculations for days, hours, minutes and seconds
  //             var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  //             var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  //             var seconds = Math.floor((distance % (1000 * 60)) / 1000).toLocaleString(undefined, { minimumIntegerDigits: 2 });

  //             setCounter(hours + ":" + minutes + ":" + seconds)
  //         }

  //     }
  // }, 1000);

  const fetchData = async () => {
    const response = await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/quiz/" +
        currentid,
      {
        headers: new Headers({
          "x-access-token": localStorage.getItem("token"),
        }),
      }
    ).then((res) => res.json());

    setData(response);
  };

  const fetchQID = async () => {
    const response = await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/quiz/getid",
      {
        headers: new Headers({
          "x-access-token": localStorage.getItem("token"),
        }),
      }
    ).then((res) => res.json());

    setQid(response);
    currentid = response[0]._id;
  };

  const isExistQuiz = async () => {
    const response = await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/score/isexist/" +
        localStorage.getItem("user_id"),
      {
        headers: new Headers({
          "x-access-token": localStorage.getItem("token"),
        }),
      }
    ).then((res) => res.json());

    if (response.length > 0) {
      setFinished(true);
    }
  };

  const handleSubmit = async () => {
    await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/score/submit",
      {
        method: "POST",
        headers: new Headers({
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          answer: answer,
        }),
      }
    );
    setModalVisible(false);
    setFinished(true);
  };

  useEffect(async () => {
    await isExistQuiz();

    if (!finished) {
      await fetchQID();
      await fetchData();
    }
  }, []);

  const onChange = (e, id) => {
    setAnswer({ ...answer, [id]: e.target.value.replaceAll(" ", "") });
  };

  const handleSteps = (current) => {
    setStepCurrent(current);
  };

  const handleNextPage = () => {
    if (stepCurrent !== qid.length - 1) {
      currentid = qid[stepCurrent + 1]._id;
      setStepCurrent(stepCurrent + 1);
      setData(null);
      fetchData();
    }
  };

  const handlePrevPage = () => {
    if (stepCurrent >= 1) {
      currentid = qid[stepCurrent - 1]._id;
      setStepCurrent(stepCurrent - 1);
      setData(null);
      fetchData();
    }
  };

  const showModalVisible = () => {
    setModalVisible(true);
  };

  const closeModalVisible = () => {
    setModalVisible(false);
  };

  if (finished) return <SubmitSuccessPage />;

  // if (new Date("Mar 25, 2022 12:00:00").getTime() - new Date().getTime() < 0) return <SubmitFailedPage />
  if (new Date("May 19, 2022 00:01:00").getTime() - new Date().getTime() >= 0)
    return <SubmitFailedPage />;

  return (
    <div
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        rowGap: "30pt",
      }}
    >
      {/* <center><h1 style={{ padding: "10px", border: "2px solid #1890ff", width: "10%", borderRadius: "15px" }} id="timer">{counter}</h1><hr /></center> */}
      <div className="row">
        <div className="col-1">
          {stepCurrent !== 0 && (
            <FaArrowCircleLeft
              className="backicon"
              size={50}
              onClick={handlePrevPage}
            />
          )}
        </div>
        <div className="col">
          <Steps
            type="navigation"
            size="default"
            current={stepCurrent}
            onChange={handleSteps}
          >
            {_.range(1, qid.length + 1).map((el) => {
              return <Steps.Step disabled key={"step" + el} />;
            })}
          </Steps>
        </div>
        <div className="col-1">
          {stepCurrent !== qid.length - 1 ? (
            <FaArrowCircleRight
              className="backicon"
              size={50}
              onClick={handleNextPage}
            />
          ) : (
            <FaCheckCircle size={50} color="green" onClick={showModalVisible} />
          )}
        </div>
      </div>

      {!data || !qid ? (
        <center>
          <Spin size="large" />
        </center>
      ) : (
        <div
          key={data._id}
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          {data.desc && <h4>{data.desc}</h4>}
          {/* {data.img && <center> <img src={data.img} alt='img' /> </center>} */}
          {data.img ? (
            <div className="row">
              <div className="col-5">
                <center>
                  {" "}
                  <img src={data.img} width="100%" alt="img" />{" "}
                </center>
              </div>
              <div className="col-7">
                {data.sub.map((s, i) => {
                  return (
                    <div
                      key={"s" + i}
                      style={{
                        background: "rgb(241, 241, 241)",
                        padding: "10px",
                      }}
                    >
                      <h5>
                        {s.no}) {s.desc}
                      </h5>
                      {s.input_type === "input" && (
                        <Input
                          defaultValue={
                            answer["no" + s.no] ? answer["no" + s.no] : ""
                          }
                          onChange={(e) => onChange(e, "no" + s.no)}
                          id={"no" + s.no}
                        />
                      )}
                      {s.input_type === "number" && (
                        <Input
                          type="number"
                          defaultValue={
                            answer["no" + s.no] ? answer["no" + s.no] : ""
                          }
                          onChange={(e) => onChange(e, "no" + s.no)}
                          id={"no" + s.no}
                        />
                      )}
                      {s.input_type === "textarea" && (
                        <Input.TextArea
                          rows={8}
                          placeholder={s.placeholder}
                          defaultValue={
                            answer["no" + s.no] ? answer["no" + s.no] : ""
                          }
                          onChange={(e) => onChange(e, "no" + s.no)}
                          id={"no" + s.no}
                        />
                      )}

                      {/* <Radio.Group 

                style={{ display: "flex", justifyContent: "space-around", width: "100%", flexDirection: "column", rowGap: "10px" }}
                size="large"
              >
                <Radio.Button style={{ height: "fit-content" }} value={1}><p style={{ fontSize: "15pt" }}>1. {parse(s.choice[0])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={2}><p style={{ fontSize: "15pt" }}>2. {parse(s.choice[1])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={3}><p style={{ fontSize: "15pt" }}>3. {parse(s.choice[2])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={4}><p style={{ fontSize: "15pt" }}>4. {parse(s.choice[3])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={5}><p style={{ fontSize: "15pt" }}>5. {parse(s.choice[4])}</p></Radio.Button>
              </Radio.Group> */}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              {data.sub.map((s, i) => {
                return (
                  <div
                    key={"s" + i}
                    style={{
                      background: "rgb(241, 241, 241)",
                      padding: "10px",
                    }}
                  >
                    <h5>
                      {s.no}) {s.desc}
                    </h5>
                    {s.input_type === "input" && (
                      <Input
                        defaultValue={
                          answer["no" + s.no] ? answer["no" + s.no] : ""
                        }
                        onChange={(e) => onChange(e, "no" + s.no)}
                        id={"no" + s.no}
                      />
                    )}
                    {s.input_type === "number" && (
                      <Input
                        type="number"
                        defaultValue={
                          answer["no" + s.no] ? answer["no" + s.no] : ""
                        }
                        onChange={(e) => onChange(e, "no" + s.no)}
                        id={"no" + s.no}
                      />
                    )}
                    {s.input_type === "textarea" && (
                      <Input.TextArea
                        rows={8}
                        placeholder={s.placeholder}
                        defaultValue={
                          answer["no" + s.no] ? answer["no" + s.no] : ""
                        }
                        onChange={(e) => onChange(e, "no" + s.no)}
                        id={"no" + s.no}
                      />
                    )}

                    {/* <Radio.Group 

                style={{ display: "flex", justifyContent: "space-around", width: "100%", flexDirection: "column", rowGap: "10px" }}
                size="large"
              >
                <Radio.Button style={{ height: "fit-content" }} value={1}><p style={{ fontSize: "15pt" }}>1. {parse(s.choice[0])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={2}><p style={{ fontSize: "15pt" }}>2. {parse(s.choice[1])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={3}><p style={{ fontSize: "15pt" }}>3. {parse(s.choice[2])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={4}><p style={{ fontSize: "15pt" }}>4. {parse(s.choice[3])}</p></Radio.Button>
                <Radio.Button style={{ height: "fit-content" }} value={5}><p style={{ fontSize: "15pt" }}>5. {parse(s.choice[4])}</p></Radio.Button>
              </Radio.Group> */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      <div className="row">
        <div className="col-1">
          {stepCurrent !== 0 && (
            <FaArrowCircleLeft
              className="backicon"
              size={50}
              onClick={handlePrevPage}
            />
          )}
        </div>
        <div className="col">
          <Steps
            type="navigation"
            size="default"
            current={stepCurrent}
            onChange={handleSteps}
          >
            {_.range(1, qid.length + 1).map((el) => {
              return <Steps.Step disabled key={"step" + el} />;
            })}
          </Steps>
        </div>
        <div className="col-1">
          {stepCurrent !== qid.length - 1 ? (
            <FaArrowCircleRight
              className="backicon"
              size={50}
              onClick={handleNextPage}
            />
          ) : (
            <FaCheckCircle size={50} color="green" onClick={showModalVisible} />
          )}
        </div>
      </div>

      {modalVisible && (
        <Modal
          title="Modal"
          visible={modalVisible}
          onOk={handleSubmit}
          onCancel={closeModalVisible}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <p>ยืนยันการส่งคำตอบ? เมื่อส่งแล้วจะไม่สามารถแก้ไขคำตอบได้</p>
        </Modal>
      )}
    </div>
  );
}

function SubmitSuccessPage() {
  const [score, setScore] = useState(null);
  const [loading, setLoading] = useState(true);

  const isExistQuiz = async () => {
    const response = await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/score/isexist/" +
        localStorage.getItem("user_id"),
      {
        headers: new Headers({
          "x-access-token": localStorage.getItem("token"),
        }),
      }
    ).then((res) => res.json());

    if (response.length > 0) {
      setScore(response[0].score);
      setLoading(false);
    }
  };

  useEffect(() => {
    isExistQuiz();
  }, []);

  if (loading) return <Spin size="large" />;

  return (
    <div>
      <Result status="success" title="คุณได้ Quiz เรียบร้อยแล้ว">
        <center>
          <h4>คะแนนที่ได้ {score} / 24</h4>{" "}
        </center>
      </Result>
    </div>
  );
}

function SubmitFailedPage() {
  return (
    <Result
      status="warning"
      title="ยังไม่เปิด Quiz ในขณะนี้"
      subTitle="ระบบเปิด Quiz วันที่ 19 พฤษภาคม 2565 เวลา 00:01 น."
    />
  );
}
