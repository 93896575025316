import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Modal } from "antd";
import logo from "../assets/logo.png";
import "../styles/backgroundAnimation.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function ResetPassword({ match }) {
  const history = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [username, setUsername] = useState(null);

  const layout = {
    labelCol: {
      span: 4,
    },
    // wrapperCol: {
    //     span: 20,
    // },
  };
  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  /* eslint-enable no-template-curly-in-string */

  useEffect(() => {
    const reset = async () => {
      await axios
        .get(
          "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/reset/" +
            match.params.token,
          {}
        )
        .then((response) => {
          setIsValid(true);
          setUsername(response.data.username);
        })
        .catch((error) => {
          errorModal();
        });
    };

    reset();
  }, [match.params.token]);

  const updatePassword = async (values) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", values.user.password);

    await fetch(
      `https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/update/password`,
      {
        method: "PUT",
        body: formData,
      }
    ).then((res) => {
      if (res.status === 200) {
        window.location.href = "/login";
      } else {
        errorModal();
      }
    });
  };

  const errorModal = () => {
    return Modal.error({
      title: "Error",
      content: "Invalid token link",
      // okButtonProps: { style:{display:"none"} },
    });
  };

  return (
    <div>
      <div className="container-body" id="backgroundMain">
        <div className="purple"></div>
        <div className="medium-blue"></div>
        <div className="light-blue"></div>
        <div className="red"></div>
        <div className="orange"></div>
        <div className="yellow"></div>
        <div className="cyan"></div>
        <div className="light-green"></div>
        <div className="lime"></div>
        <div className="magenta"></div>
        <div className="lightish-red"></div>
        <div className="pink"></div>
        <div></div>
      </div>
      {isValid && (
        <div className="row">
          <div className="card-item-register">
            <img
              src={logo}
              width="100vw"
              alt="alk-logo"
              style={{
                borderRadius: "1000px",
                zIndex: 1,
                position: "relative",
                top: "35px",
              }}
            />
            <Card
              className="inner-card-register"
              style={{ borderRadius: "15px" }}
              headStyle={{
                textAlign: "center",
                fontSize: "20px",
                backgroundColor: "rgba(0,0,0,0.5) !important",
              }}
              bodyStyle={{ backgroundColor: "transparent" }}
            >
              <FaArrowAltCircleLeft
                className="backicon"
                size={35}
                onClick={() => history.replace("/")}
              />

              <Form
                {...layout}
                onFinish={updatePassword}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["user", "password"]}
                  label="รหัสผ่าน"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกรหัสผ่าน",
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  name={["user", "confirm_password"]}
                  label="กรอกรหัสผ่านอีกครั้ง"
                  dependencies={["user", "password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกรหัสผ่าน",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          !value ||
                          getFieldValue(["user", "password"]) === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item>
                  <Button block type="primary" size="large" htmlType="submit">
                    ยืนยัน
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}
