import React from "react";
import { Form, Input, Button, Card, Modal } from "antd";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import "../styles/backgroundAnimation.css";
export default function Login() {
  const handleLogin = async (values) => {
    try {
      const formData = new FormData();
      formData.append("username", values.user.username);
      formData.append("password", values.user.password);

      await fetch(
        "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/login",
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        if (res.status === 200) {
          res
            .text()
            .then((token) => {
              window.localStorage.setItem("token", token);
            })
            .then(async () => {
              try {
                const id = await fetch(
                  `https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/id/${values.user.username}`,
                  {
                    headers: new Headers({
                      "x-access-token": localStorage.getItem("token"),
                    }),
                  }
                ).then((res) => res.json());
                window.localStorage.setItem("user_id", id);
                window.location.href = "/";
              } catch (error) {
                console.log("error", error);
              }
            });
        } else {
          errorModal();
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    // wrapperCol: {
    //     span: 20,
    // },
  };
  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  /* eslint-enable no-template-curly-in-string */

  const errorModal = () => {
    return Modal.error({
      title: "Error",
      content: "Username or password doesn't exist.",
    });
  };

  return (
    <div>
      <div className="container-body" id="backgroundMain">
        <div className="purple"></div>
        <div className="medium-blue"></div>
        <div className="light-blue"></div>
        <div className="red"></div>
        <div className="orange"></div>
        <div className="yellow"></div>
        <div className="cyan"></div>
        <div className="light-green"></div>
        <div className="lime"></div>
        <div className="magenta"></div>
        <div className="lightish-red"></div>
        <div className="pink"></div>
        <div></div>
      </div>
      <div className="row">
        <div className="card-item-login">
          <img
            src={logo}
            width="100vw"
            alt="alk-logo"
            style={{
              borderRadius: "1000px",
              zIndex: 1,
              position: "relative",
              top: "35px",
            }}
          />
          <Card
            className="inner-card-login"
            title=" "
            style={{ borderRadius: "15px" }}
            headStyle={{
              textAlign: "center",
              fontSize: "20px",
              backgroundColor: "rgba(0,0,0,0.5) !important",
            }}
            bodyStyle={{ backgroundColor: "transparent" }}
          >
            <Form
              {...layout}
              onFinish={handleLogin}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={["user", "username"]}
                label="ชื่อผู้ใช้"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อผู้ใช้",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["user", "password"]}
                label="รหัสผ่าน"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกรหัสผ่าน",
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" size="large" htmlType="submit">
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
              <center>
                <div>
                  <Link to="/register">สร้างบัญชีใหม่</Link>
                </div>
                {/* <div>
                                    <Link to="/forgot">ลืมรหัสผ่าน ?</Link>
                                </div> */}
              </center>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}
