import React from "react";
import { Badge, Tag, Timeline, Avatar, Comment } from "antd";
import {
  ClockCircleOutlined,
  GithubOutlined,
  LinkedinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "../styles/main.css";
import profileImg from "../assets/profile.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faHtml5,
  faJs,
  faPhp,
  faPython,
  faCss3,
  faRProject,
  faJava,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChartLine,
  faMedal,
  faProjectDiagram,
  faDiagnoses,
} from "@fortawesome/free-solid-svg-icons";

export default function About() {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm col-md-3 offset-md-1 offset-3 fade-left">
          <img
            src={profileImg}
            loading="lazy"
            style={{ borderRadius: "200px" }}
            height={300}
            alt="profilelogo"
          />
          <h2 style={{ fontWeight: "600" }}>Ronnakorn Hompoa</h2>
          <h6 style={{ color: "gray", fontWeight: "600" }}>
            May the bug be with you.
          </h6>
          <b>E-mail: kasettakorn_techno@hotmail.com</b>
          <br />
          {/* <b>Website: <a href="https://alkornithm.com/" rel="noreferrer">alkornithm.com</a></b><br /> */}
          <a
            href="https://github.com/kasettakorn"
            target="_blank"
            rel="noreferrer"
          >
            <Tag icon={<GithubOutlined />} color="#171515 ">
              GitHub
            </Tag>
          </a>
          <a
            href="https://th.linkedin.com/in/ronnakorn-hompoa-5b45b9208"
            target="_blank"
            rel="noreferrer"
          >
            <Tag icon={<LinkedinOutlined />} color="#55acee ">
              LinkedIn
            </Tag>
          </a>
        </div>
        <div className="col-12 col-sm-8 col-md-7 offset-md-1 offset-lg-0 offset-xs-0 fade-right">
          <center>
            <h3>Education & Work Experience</h3>
          </center>
          <Timeline mode="alternate">
            <Timeline.Item>
              <h6>
                <b>2001-2010</b>
                <br />
                Bamroongsitsuksa School
              </h6>
            </Timeline.Item>
            <Timeline.Item>
              <h6>
                <b>2010-2016</b>
                <br />
                Singsamut School
              </h6>
            </Timeline.Item>
            <Timeline.Item color="green">
              <h6>
                <b>2016-2020</b>
                <br />
                Bachelor Degree in Computer Science
                <br />
                Faculty of Applied Science (🥈 Second class Honour)
                <br />
                King Mongkut's University of Technology North Bangkok
              </h6>
            </Timeline.Item>
            <Timeline.Item color="red">
              <h6>
                <b>May-November 2019</b>
                <br />
                Co-operative Education Student
                <br />
                SCG Chemical Co.,Ltd
              </h6>
            </Timeline.Item>
            <Timeline.Item color="green">
              <h6>
                <b>July 2020-September 2022</b>
                <br />
                System Analyst
                <br />
                Samart Comtech Co.,Ltd
              </h6>
            </Timeline.Item>
            <Timeline.Item
              color="pink"
              label={
                <div style={{ marginLeft: "7px" }}>
                  <h6>
                    <b>August 2023-Present</b>
                    <br />
                    Master's Degree in Computer Science (Data Science)
                    <br />
                    Faculty of Engineering
                    <br />
                    Chulalongkorn Univeristy
                  </h6>
                </div>
              }
            >
              <h6>
                <b>October 2022-Present</b>
                <br />
                Full Stack Developer (AIS Team)
                <br />
                Cube SoftTech Co., Ltd
              </h6>
            </Timeline.Item>
          </Timeline>
        </div>
      </div>
      <br />
      <hr className="fade-left" />

      <div className="row fade-top">
        <div className="col offset-md-1">
          <h3>Awards & Participated Competition</h3>
        </div>
      </div>

      <div className="row">
        <div className="col fade-top">
          <Timeline className="timeline" mode="alternate">
            <Timeline.Item color="gold">
              <h6>
                <b>2013</b>
                <br />
                <FontAwesomeIcon icon={faMedal} color="#EEB609" size="2x" />
                Gold medal - Computer Programming Competition (District)
                <br />
                Student craft fair #63
              </h6>
            </Timeline.Item>
            <Timeline.Item color="gold">
              <h6>
                <b>2014</b>
                <br />
                Training website camp - Junior Webmaster Camp #6
                <br />
                Chandrakasem Rajabhat University
                <br />
                <br />
                <FontAwesomeIcon
                  icon={faMedal}
                  color="#EEB609"
                  size="1x"
                />{" "}
                Winner - Computer Programming Competition (District)
                <br />
                <FontAwesomeIcon
                  icon={faMedal}
                  color="#CD7F32"
                  size="1x"
                />{" "}
                Bronze medal - Computer Programming Competition <br />
                (Central and Eastern)
                <br />
                Student craft fair #64
              </h6>
            </Timeline.Item>
            <Timeline.Item color="green">
              <h6>
                <b>2015</b>
                <br />
                5th place - Google search contest (Be Fast E-Searching)
                <br />
                Eastern Technological College, Chonburi
              </h6>
            </Timeline.Item>
            <Timeline.Item color="green">
              <h6>
                <b>2016</b>
                <br />
                4th place - Academic Computer Quiz Competition (Final Round)
                <br />
                King Mongkut's University of Technology Thonburi
              </h6>
            </Timeline.Item>
            <Timeline.Item color="red">
              <h6>
                <b>2018</b>
                <br />
                Teacher assistant in Computer Programming 2<br />
                King Mongkut's University of Technology North Bangkok
              </h6>
            </Timeline.Item>
            <Timeline.Item color="blue">
              <h6>
                <b>2020</b>
                <br />
                <FontAwesomeIcon icon={faMedal} color="#EEB609" size="2x" />
                Gold medal - Co-operative project contest
                <br />
                Health Surveillance Tracking System (Return to work)
                <br />
                King Mongkut's University of Technology North Bangkok
              </h6>
            </Timeline.Item>
          </Timeline>
        </div>
      </div>

      <hr className="fade-left" />
      <div className="row fade-top">
        <div className="col offset-md-1">
          <h3>Certificates</h3>
        </div>
      </div>

      <div className="row">
        <div className="col col-sm col-md-6 offset-md-1 fade-top">
          <Badge.Ribbon text="Score: 82/100" color="green">
            <Comment
              author={
                <div className="row">
                  <div className="col-lg col-md-10 col-sm col-9">
                    <h6>98-388: MTA: Introduction to Programming using Java</h6>
                  </div>
                </div>
              }
              avatar={<FontAwesomeIcon icon={faMicrosoft} size="2x" />}
              content={
                <div>
                  <p>Credential ID: m7eU-uTBw</p>
                  <a
                    href="https://www.certiport.com/portal/Pages/ViewTranscript.aspx?printview=true&defaultlang=ENU"
                    rel="noreferrer"
                    target="_blank"
                  >
                    View credential
                  </a>
                </div>
              }
            />
          </Badge.Ribbon>
          <Comment
            author={
              <div className="row">
                <div className="col-lg col-md-10 col-sm col-9">
                  <h6>MERN Full stack Training Course</h6>
                </div>
              </div>
            }
            avatar={
              <Avatar
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3IHNxqN6dE2qfXxcGFAN7eaCFJNn9ku8jOqQo27S0RZbhbg0wLEMcz2RO5sxLME3ni68&usqp=CAU"
                alt="cmdev"
                size="2x"
              />
            }
            content={
              <div>
                <p>Credential ID: CM9B767</p>
                <a
                  href="https://scontent.fbkk5-7.fna.fbcdn.net/v/t39.30808-6/275799595_4928500963884899_6781608846990008926_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeHc7C8b5kqo2RV_L7EQrxsCrcY3xBb2rhytxjfEFvauHJyBIhwXjrhICiLEOoC_ig2kkG-3_my6L85tYxtpOGB3&_nc_ohc=UZAnAAfMJ14AX8HAvEE&_nc_ht=scontent.fbkk5-7.fna&oh=00_AT-z0Q8cqZNDOt5JW0iy_DdlEkjbC6D_xy3W0nVTN8Kf1Q&oe=6233F8C2"
                  rel="noreferrer"
                  target="_blank"
                >
                  View credential
                </a>
              </div>
            }
          />
        </div>
      </div>

      <hr className="fade-left" />
      <div className="row fade-top">
        <div className="col offset-md-1">
          <h3>Programming Skills</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm col-md-5 col-lg-3 offset-md-1 fade-top">
          <Comment
            author={"Senior web interviewer"}
            avatar={<UserOutlined style={{ fontSize: "40px" }} />}
            content={<p>What web programming skills do you have ?</p>}
          >
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>ReactJS</h6>}
                avatar={
                  <FontAwesomeIcon icon={faReact} color="#61DBFB" size="3x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>HTML5</h6>}
                avatar={
                  <FontAwesomeIcon icon={faHtml5} color="#f06529" size="4x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={<h6>CSS</h6>}
                avatar={
                  <FontAwesomeIcon icon={faCss3} color="#2965f1" size="3x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>JavaScript</h6>}
                avatar={
                  <FontAwesomeIcon icon={faJs} color="#f0db4f" size="3x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>TypeScript</h6>}
                avatar={
                  <Avatar
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1024px-Typescript_logo_2020.svg.png?20210506173343"
                    alt="ts"
                    size="1x"
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>PHP</h6>}
                avatar={
                  <FontAwesomeIcon icon={faPhp} color="#8993be" size="2x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Basic" color="red">
              <Comment
                author={<h6>.NET CORE (C#)</h6>}
                avatar={
                  <Avatar
                    src="https://cdn.iconscout.com/icon/free/png-256/microsoft-dotnet-1175177.png"
                    alt="dotnet"
                    size="1x"
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
          </Comment>
        </div>
        <div className="col-12 col-sm col-md-5 col-lg-3 fade-top">
          <Comment
            author={"Senior data science interviewer"}
            avatar={<UserOutlined style={{ fontSize: "40px" }} />}
            content={<p>What AI or Machine Learning skills do you have ?</p>}
          >
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={<h6>Python</h6>}
                avatar={
                  <FontAwesomeIcon icon={faPython} color="#4B8BBE" size="3x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Basic" color="red">
              <Comment
                author={<h6>Statistics</h6>}
                avatar={<FontAwesomeIcon icon={faChartLine} size="3x" />}
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Basic" color="red">
              <Comment
                author={<h6>R</h6>}
                avatar={
                  <FontAwesomeIcon
                    icon={faRProject}
                    color="#276DC2"
                    size="3x"
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
          </Comment>
        </div>
        <div className="col-12 col-md-5 col-sm-6 col-lg-4  fade-top">
          <Comment
            author={"Senior mobile app interviewer"}
            avatar={<UserOutlined style={{ fontSize: "40px" }} />}
            content={<p>What mobile application skills do you have ?</p>}
          >
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={<h6>React Native</h6>}
                avatar={
                  <FontAwesomeIcon icon={faReact} color="#61DBFB" size="3x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Basic" color="red">
              <Comment
                author={<h6>Java</h6>}
                avatar={
                  <FontAwesomeIcon icon={faJava} color="#f89820" size="4x" />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Excellent" color="green">
              <Comment
                author={<h6>Flutter</h6>}
                avatar={
                  <Avatar
                    src="https://logowik.com/content/uploads/images/flutter5786.jpg"
                    alt="flutter"
                    size={50}
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
          </Comment>
        </div>
        <div className="col-12 col-sm col-md-5 col-lg-4 offset-lg-1 fade-top">
          <Comment
            author={"IT management interviewer"}
            avatar={<UserOutlined style={{ fontSize: "40px" }} />}
            content={<p>What other programming skills do you have ?</p>}
          >
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={
                  <h6>
                    C<br />/ C++
                  </h6>
                }
                avatar={
                  <Avatar
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png"
                    alt="cpp"
                    size={40}
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={<h6>UML</h6>}
                avatar={
                  <Avatar
                    src="https://sites.google.com/site/statenow/_/rsrc/1472781249952/mainpage/UML.gif"
                    alt="uml"
                    size={40}
                  />
                }
                content={<p></p>}
              />
            </Badge.Ribbon>
          </Comment>
        </div>
        <div className="col-12 col-sm-6 offset-md-1 col-md-5 col-lg-5 fade-top">
          <Comment
            author={"PM interviewer"}
            avatar={<UserOutlined style={{ fontSize: "40px" }} />}
            content={<p>What other skills do you have ?</p>}
          >
            <Badge.Ribbon text="Basic" color="red">
              <Comment
                author={
                  <h6>
                    Project
                    <br />
                    Management
                  </h6>
                }
                avatar={<FontAwesomeIcon icon={faProjectDiagram} size="2x" />}
                content={<p></p>}
              />
            </Badge.Ribbon>
            <Badge.Ribbon text="Good" color="orange">
              <Comment
                author={
                  <h6>
                    System
                    <br />
                    Analysis
                  </h6>
                }
                avatar={<FontAwesomeIcon icon={faDiagnoses} size="2x" />}
                content={<p></p>}
              />
            </Badge.Ribbon>
          </Comment>
        </div>
      </div>

      <hr className="fade-left" />
      <div className="row fade-top">
        <div className="col offset-md-1">
          <h3>Language Proficiency Tests</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm col-md-4 offset-md-1 fade-top">
          <Badge.Ribbon text="Score: 675/990" color="pink">
            <Comment
              author={<h6>TOEIC</h6>}
              content="Issue Date: 6 Aug 2022"
              avatar={
                <Avatar
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/ETS_Logo.svg/2560px-ETS_Logo.svg.png"
                  alt="toeic"
                  size="3x"
                  shape="square"
                />
              }
            />
          </Badge.Ribbon>
        </div>

        <div className="col-12 col-sm col-md-3 fade-top">
          <Badge.Ribbon text="Score: 61/120" color="pink">
            <Comment
              author={<h6>CU-TEP</h6>}
              content="Issue Date: 28 Jan 2023"
              avatar={
                <Avatar
                  src="https://www.housing.arch.chula.ac.th/new/files/news/ttGmcVD5uYThu34624.png"
                  alt="cutep"
                  size="3x"
                  shape="square"
                />
              }
            />
          </Badge.Ribbon>
        </div>
      </div>

      <hr className="fade-left" />
      <div className="row fade-top">
        <div className="col offset-md-1">
          <h3>Languages</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm col-md-4 offset-md-1 fade-top">
          <Badge.Ribbon text="Native" color="purple">
            <Comment
              author={<h6>Thai</h6>}
              avatar={
                <Avatar
                  src="https://seekicon.com/free-icon-download/thailand-flag_1.svg"
                  alt="thai"
                  size="2x"
                  shape="square"
                />
              }
              content={<p></p>}
            />
          </Badge.Ribbon>
        </div>
        <div className="col-12 col-sm col-md-3 fade-top">
          <Badge.Ribbon text="Good" color="orange">
            <Comment
              author={<h6>English</h6>}
              avatar={
                <Avatar
                  src="https://seekicon.com/free-icon-download/united-states-of-america-flag_1.svg"
                  alt="english"
                  size="2x"
                />
              }
              content={<p></p>}
            />
          </Badge.Ribbon>
        </div>
        <div className="col-12 col-sm col-md-3 fade-top">
          <Badge.Ribbon text="Basic" color="red">
            <Comment
              author={<h6>Japanese</h6>}
              avatar={
                <Avatar
                  src="https://seekicon.com/free-icon-download/japan-flag_1.svg"
                  alt="japanese"
                  size="2x"
                />
              }
              content={<p></p>}
            />
          </Badge.Ribbon>
        </div>
      </div>
    </div>
  );
}
