export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
};

export const isLogin = () => {
  if (isValidToken) {
    return true;
  }
  logout();
  return false;
};

const isValidToken = async () => {
  const formData = new FormData();
  formData.append("token", localStorage.getItem("token"));
  const response = await fetch(
    "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/verify",
    {
      method: "POST",
      body: formData,
    }
  ).then((res) => {
    return res.status;
  });
  if (response === 401 || response === 403 || response === 500) {
    return false;
  }
  return true;
};

// export const logout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user_id");
// }

// export const isLogin = async () => {
//     const formData = new FormData();
//     formData.append("token", localStorage.getItem('token'));
//     const response = await fetch("https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/verify", {
//         method: "POST",
//         body: formData
//     }).then(res => {
//         return res.status;
//     })
//     if (response === 401 || response === 403 || response === 500) {
//         return false;
//     }
//     return true;
// }
