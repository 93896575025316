import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import "antd/dist/antd.css";

export default function Admin() {
  const [columns, setColumns] = useState([
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "fullName",
      align: "left",
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "nickName",
      align: "left",
    },
    {
      title: "คะแนนรวม (12)",
      dataIndex: "score",
      align: "center",
    },
  ]);
  const [data, setData] = useState(null);
  const [problems, setProblems] = useState(null);

  useEffect(() => {
    const fetchScore = async () => {
      const response = await fetch(
        "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/score/",
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      ).then((res) => res.json());
      setData(response);

      const problemResponse = await fetch(
        "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/problem",
        {
          headers: new Headers({
            "x-access-token": localStorage.getItem("token"),
          }),
        }
      ).then((res) => res.json());
      setProblems(problemResponse);
    };
    fetchScore();
  }, []);

  if (!data) {
    return <Spin />;
  }

  return (
    <div>
      <table
        className="table table-bordered"
        style={{
          width: "70vw",
          marginLeft: "auto",
          marginRight: "auto",
          border: "4px solid black",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            align="center"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <th style={{ fontFamily: "Kanit", fontSize: "12pt" }}>ลำดับ</th>
            <th style={{ fontFamily: "Kanit", fontSize: "12pt" }}>
              ชื่อ-นามสกุล
            </th>
            <th style={{ fontFamily: "Kanit", fontSize: "12pt" }}>ชื่อเล่น</th>
            <th style={{ fontFamily: "Kanit", fontSize: "12pt" }}>
              วันที่ส่งคำตอบ
            </th>
            <th style={{ fontFamily: "Kanit", fontSize: "12pt" }}>
              คะแนนรวม (24)
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            return (
              <tr key={i} className={"table-light"}>
                <td align="center">{i + 1}</td>
                <td align="left">{`${d.firstName} ${d.lastName}`}</td>
                <td align="left">{d.nickName}</td>

                {/* <td align="center">{d.score}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
