import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Switch, Link, Route } from "react-router-dom";
import Courses from "./pages/Courses";
import "./App.css";
import About from "./pages/About";
import { Layout, Menu, Affix, BackTop, Avatar, Button } from "antd";
import Grader from "./pages/Grader";
import SubMenu from "antd/lib/menu/SubMenu";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Register from "./pages/Register";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Error from "./pages/Error";
import { isLogin, logout } from "./utils";
import Problem from "./pages/Problem";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Admin from "./pages/Admin";
import Quiz from "./pages/Quiz";

const { Header, Content, Footer } = Layout;

export default function App() {
  const [res, setRes] = useState(null);
  //https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/
  useEffect(() => {
    const readProfileImg = async () => {
      try {
        const response = await fetch(
          `https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/${localStorage.getItem(
            "user_id"
          )}`,
          {
            headers: new Headers({
              "x-access-token": localStorage.getItem("token"),
            }),
          }
        ).then((res) => res.json());
        setRes(response);
      } catch (error) {}
    };

    try {
      if (isLogin()) {
        readProfileImg();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSignout = () => {
    logout();
    window.location.href = "/login";
  };

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact component={Login} path="/login" />
        <PublicRoute exact component={Register} path="/register" />
        <PublicRoute exact component={ForgotPassword} path="/forgot" />
        <PublicRoute exact component={ResetPassword} path="/reset/:token" />
        <Fragment>
          <Layout className="layout">
            <Affix>
              <Header>
                <div className="logo" />
                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={[
                    window.location.pathname.includes("courses")
                      ? "2"
                      : window.location.pathname.includes("about")
                      ? "3"
                      : window.location.pathname.includes("quiz")
                      ? "4"
                      : window.location.pathname.includes("admin")
                      ? "7"
                      : window.location.pathname.includes("grader") ||
                        window.location.pathname.includes("problem")
                      ? "5"
                      : "1",
                  ]}
                >
                  <Menu.Item key={1}>
                    <Link style={{ textDecoration: "none" }} to="/">
                      Home
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={2}>
                    <Link style={{ textDecoration: "none" }} to="/courses">
                      Courses
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={3}>
                    <Link style={{ textDecoration: "none" }} to="/about">
                      About me
                    </Link>
                  </Menu.Item>

                  {res && (
                    <Menu.Item key={5}>
                      <Link style={{ textDecoration: "none" }} to="/grader">
                        Grader
                      </Link>
                    </Menu.Item>
                  )}
                  {res && res.role === "admin" && (
                    <Menu.Item key={7}>
                      <Link style={{ textDecoration: "none" }} to="/admin">
                        Admin
                      </Link>
                    </Menu.Item>
                  )}
                  {!res ? (
                    <Menu.Item key={99} style={{ marginLeft: "auto" }}>
                      <Button type="primary">
                        <a href="/login">เข้าสู่ระบบ</a>
                      </Button>
                    </Menu.Item>
                  ) : (
                    <SubMenu
                      key={6}
                      style={{ marginLeft: "auto" }}
                      title={
                        res && (
                          <Avatar
                            style={{
                              backgroundColor: "#87d068",
                            }}
                            size="large"
                            src={res.profileImg}
                          />
                        )
                      }
                    >
                      <Menu.ItemGroup
                        title={res && res.firstName + " " + res.lastName}
                      >
                        <Menu.Item key="profile">Profile</Menu.Item>
                        <Menu.Item
                          key="signout"
                          onClick={handleSignout}
                          style={{ color: "red" }}
                        >
                          Sign Out
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </SubMenu>
                  )}
                </Menu>
              </Header>
            </Affix>
            <Content style={{ backgroundColor: "white", padding: "20px" }}>
              <Switch>
                <PublicRoute exact component={Main} path="/" />
                <PublicRoute exact component={Courses} path="/courses" />
                <PublicRoute exact component={About} path="/about" />
                {res && (
                  <PrivateRoute
                    exact
                    restricted={true}
                    component={Quiz}
                    path="/quiz"
                  />
                )}
                {res && (
                  <PrivateRoute
                    exact
                    restricted={true}
                    component={Grader}
                    path="/grader"
                  />
                )}
                {res && (
                  <PrivateRoute
                    exact
                    restricted={true}
                    component={Problem}
                    path="/problem/:id"
                  />
                )}
                {res && res.role === "admin" && (
                  <PrivateRoute
                    exact
                    restricted={true}
                    component={Admin}
                    path="/admin"
                  />
                )}
                <Route component={Error} />
              </Switch>
            </Content>
            <Footer
              style={{
                textAlign: "center",
                zIndex: "0",
                backgroundColor: "#001529",
              }}
            >
              <p
                style={{ fontSize: "18px", color: "white", fontWeight: "500" }}
              >
                {" "}
                © 2019-{new Date().getFullYear()} Ronnakorn Hompoa all right
                reserved.
              </p>
            </Footer>
          </Layout>
        </Fragment>
      </Switch>
      <BackTop />
    </BrowserRouter>
  );
}
