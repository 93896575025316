import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spin } from "antd";
import "../styles/grader.css";

export default function Grader() {
  const problemRef = useRef(null);
  const [problems, setProblems] = useState(null);
  const [scores, setScores] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [loadingID, setloadingID] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/problem",
        {
          headers: new Headers({
            "x-access-token": localStorage.getItem("token"),
          }),
        }
      )
        .then((res) => res.json())
        .then(async (problem) => {
          setProblems(problem);

          const response = await fetch(
            "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/grader/" +
              localStorage.getItem("user_id"),
            {
              headers: new Headers({
                "x-access-token": localStorage.getItem("token"),
              }),
            }
          ).then((res) => res.json());
          console.log(response);

          let json = [],
            j = 0;
          for (let i = 0; i < problem.length; i++) {
            if (
              j < response.length &&
              response[j].problem_id === problem[i]._id
            ) {
              json.push({
                p1: response[j].p1,
                p2: response[j].p2,
                p3: response[j].p3,
                p4: response[j].p4,
                p5: response[j].p5,
                s1: response[j].s1,
                s2: response[j].s2,
                s3: response[j].s3,
                s4: response[j].s4,
                s5: response[j].s5,
                _id: response[j]._id,
                problem_id: problem[i]._id,
                problem_name: problem[i].problem_name,
                user_id: localStorage.getItem("user_id"),
                index: i + 1,
              });
              j++;
            } else {
              json.push({
                p1: 0,
                p2: 0,
                p3: 0,
                p4: 0,
                p5: 0,
                s1: "-",
                s2: "-",
                s3: "-",
                s4: "-",
                s5: "-",
                problem_id: problem[i]._id,
                problem_name: problem[i].problem_name,
                user_id: localStorage.getItem("user_id"),
                index: i + 1,
              });
            }
          }
          setScores(json);
        });
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const handleSubmit = async (e, problem_id, problem_name, scores_id, i) => {
    setloadingID(problem_id);

    e.preventDefault();
    e.target[0]["value"] = null;
    const formData = new FormData();
    formData.append("code", uploadFile);
    formData.append("problem_id", problem_id);
    formData.append("problem_name", problem_name);
    formData.append("user_id", localStorage.getItem("user_id"));
    formData.append("index", i);
    e.target.value = null;
    await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/grader/uploadCode",
      {
        method: "POST",
        body: formData,
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    formData.append("i", 1);
    let result_arr = [],
      score_arr = [];
    for (let i = 1; i <= 5; i++) {
      formData.set("i", i);
      let res = await fetch(
        "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/grader/processCode",
        {
          method: "POST",
          body: formData,
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      ).then((res) => {
        return res.json();
      });
      result_arr.push(res.result);
      score_arr.push(res.score);
    }

    const resultData = new FormData();
    resultData.append("s1", result_arr[0]);
    resultData.append("s2", result_arr[1]);
    resultData.append("s3", result_arr[2]);
    resultData.append("s4", result_arr[3]);
    resultData.append("s5", result_arr[4]);
    resultData.append("p1", score_arr[0]);
    resultData.append("p2", score_arr[1]);
    resultData.append("p3", score_arr[2]);
    resultData.append("p4", score_arr[3]);
    resultData.append("p5", score_arr[4]);
    resultData.append("problem_id", problem_id);
    resultData.append("problem_name", problem_name);
    resultData.append("user_id", localStorage.getItem("user_id"));
    resultData.append("index", i);

    await fetch(
      `https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/grader/${problem_id}/${formData.get(
        "user_id"
      )}`,
      {
        method: "PUT",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
        body: resultData,
      }
    );
    let newScore = [...scores];
    newScore[i - 1].p1 = score_arr[0];
    newScore[i - 1].p2 = score_arr[1];
    newScore[i - 1].p3 = score_arr[2];
    newScore[i - 1].p4 = score_arr[3];
    newScore[i - 1].p5 = score_arr[4];

    newScore[i - 1].s1 = result_arr[0];
    newScore[i - 1].s2 = result_arr[1];
    newScore[i - 1].s3 = result_arr[2];
    newScore[i - 1].s4 = result_arr[3];
    newScore[i - 1].s5 = result_arr[4];

    setScores(newScore);
    setloadingID(null);
    setUploadFile(null);
  };

  if (!scores || !problems) {
    return (
      <center>
        <Spin size="large" />
      </center>
    );
  }
  console.log(scores);
  return (
    <div>
      <Modal title="Title" visible={visible} footer={null}>
        <p>กรุณาแนบไฟล์ C หรือ C++</p>
      </Modal>
      {problems.map((problem, index) => {
        return (
          <div
            className="row"
            key={problem._id}
            style={{
              opacity: loadingID === problem._id ? 0.5 : 1,
              pointerEvents: loadingID === problem._id ? "none" : "auto",
            }}
          >
            <div className="col">
              <table
                className="table-grader table table-bordered table-condensed"
                key={scores[index]._id}
              >
                <tbody>
                  <tr>
                    <th rowSpan={2}>Test Case</th>
                    <th colSpan={5} style={{ fontSize: "14pt" }}>
                      <Link
                        style={{ color: "black" }}
                        to={"/problem/" + problem._id}
                      >
                        {index + 1 + ". " + problem.problem_name}
                      </Link>
                    </th>
                    <th rowSpan={2}>คะแนนรวม</th>
                  </tr>
                  <tr>
                    <th style={{ backgroundColor: "#fcf8e3" }}>1</th>
                    <th style={{ backgroundColor: "#fcf8e3" }}>2</th>
                    <th style={{ backgroundColor: "#fcf8e3" }}>3</th>
                    <th style={{ backgroundColor: "#fcf8e3" }}>4</th>
                    <th style={{ backgroundColor: "#fcf8e3" }}>5</th>
                  </tr>
                  <tr>
                    <td>ผลการตรวจ</td>
                    {scores[index].s1 === "X" || scores[index].s1 === "T" ? (
                      <td style={{ backgroundColor: "#f2dede" }}>
                        {scores[index].s1 != null
                          ? scores[index].s1 === "X"
                            ? "❌"
                            : "⌛️"
                          : "-"}
                      </td>
                    ) : scores[index].s1 === "P" ? (
                      <td style={{ backgroundColor: "#c3e6cb" }}>
                        {scores[index].s1 != null ? "✅" : "-"}
                      </td>
                    ) : (
                      <td>
                        {scores[index].s1 != null ? scores[index].s1 : "-"}
                      </td>
                    )}
                    {scores[index].s2 === "X" || scores[index].s2 === "T" ? (
                      <td style={{ backgroundColor: "#f2dede" }}>
                        {scores[index].s2 != null
                          ? scores[index].s2 === "X"
                            ? "❌"
                            : "⌛️"
                          : "-"}
                      </td>
                    ) : scores[index].s2 === "P" ? (
                      <td style={{ backgroundColor: "#c3e6cb" }}>
                        {scores[index].s2 != null ? "✅" : "-"}
                      </td>
                    ) : (
                      <td>
                        {scores[index].s2 != null ? scores[index].s2 : "-"}
                      </td>
                    )}
                    {scores[index].s3 === "X" || scores[index].s3 === "T" ? (
                      <td style={{ backgroundColor: "#f2dede" }}>
                        {scores[index].s1 != null
                          ? scores[index].s3 === "X"
                            ? "❌"
                            : "⌛️"
                          : "-"}
                      </td>
                    ) : scores[index].s3 === "P" ? (
                      <td style={{ backgroundColor: "#c3e6cb" }}>
                        {scores[index].s3 != null ? "✅" : "-"}
                      </td>
                    ) : (
                      <td>
                        {scores[index].s3 != null ? scores[index].s3 : "-"}
                      </td>
                    )}
                    {scores[index].s4 === "X" || scores[index].s4 === "T" ? (
                      <td style={{ backgroundColor: "#f2dede" }}>
                        {scores[index].s4 != null
                          ? scores[index].s4 === "X"
                            ? "❌"
                            : "⌛️"
                          : "-"}
                      </td>
                    ) : scores[index].s4 === "P" ? (
                      <td style={{ backgroundColor: "#c3e6cb" }}>
                        {scores[index].s4 != null ? "✅" : "-"}
                      </td>
                    ) : (
                      <td>
                        {scores[index].s4 != null ? scores[index].s4 : "-"}
                      </td>
                    )}
                    {scores[index].s5 === "X" || scores[index].s5 === "T" ? (
                      <td style={{ backgroundColor: "#f2dede" }}>
                        {scores[index].s5 != null
                          ? scores[index].s5 === "X"
                            ? "❌"
                            : "⌛️"
                          : "-"}
                      </td>
                    ) : scores[index].s5 === "P" ? (
                      <td style={{ backgroundColor: "#c3e6cb" }}>
                        {scores[index].s5 != null ? "✅" : "-"}
                      </td>
                    ) : (
                      <td>
                        {scores[index].s5 != null ? scores[index].s5 : "-"}
                      </td>
                    )}

                    <td
                      rowSpan={2}
                      style={{ fontWeight: 600, fontSize: "20pt" }}
                    >
                      {scores[index].p1 +
                        scores[index].p2 +
                        scores[index].p3 +
                        scores[index].p4 +
                        scores[index].p5}
                    </td>
                  </tr>
                  <tr>
                    <td>คะแนน</td>
                    <td>{scores[index].p1 != null ? scores[index].p1 : "-"}</td>
                    <td>{scores[index].p2 != null ? scores[index].p2 : "-"}</td>
                    <td>{scores[index].p3 != null ? scores[index].p3 : "-"}</td>
                    <td>{scores[index].p4 != null ? scores[index].p4 : "-"}</td>
                    <td>{scores[index].p5 != null ? scores[index].p5 : "-"}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ float: "right" }}>
                <form
                  onSubmit={(e) =>
                    handleSubmit(
                      e,
                      problem._id,
                      problem.problem_name,
                      scores[index]._id,
                      index + 1
                    )
                  }
                >
                  <input
                    onChange={handleChange}
                    ref={problemRef}
                    name={"code" + (index + 1)}
                    type="file"
                    accept=".c, .cpp"
                  />
                  <button className="btn btn-success">Submit</button>
                </form>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
