import React, { useState } from "react";
import { Form, Input, Button, Card, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import logo from "../assets/logo.png";
import "../styles/backgroundAnimation.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export default function Register() {
  const [fileList, setFileList] = useState([]);
  const [setPreviewVisible] = useState(false);
  const [setPreviewImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const layout = {
    labelCol: {
      span: 4,
    },
    // wrapperCol: {
    //     span: 20,
    // },
  };
  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  /* eslint-enable no-template-curly-in-string */

  const onFinish = async (values) => {
    setIsLoading(true);
    let base64 = "";

    const formData = new FormData();
    let reader = new FileReader();

    // if (fileList.length !== 0) {
    //     reader.readAsDataURL(fileList[0].originFileObj);
    //     reader.onloadend = async () => {
    //         base64 = reader.result;
    //         formData.append("profileImg", base64);

    //     };
    // }

    formData.append("username", values.user.username);
    formData.append("password", values.user.password);
    formData.append("firstName", values.user.firstName);
    formData.append("lastName", values.user.lastName);
    formData.append("nickName", values.user.nickName);
    formData.append("email", values.user.email);

    await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/register",
      {
        method: "POST",
        body: formData,
      }
    ).then((res) => {
      if (res.status === 200 || res.status === 201) {
        res.text().then(() => {
          console.log(formData);
          window.location.href = "/login";
        });
      } else {
        errorModal();
        setIsLoading(false);
      }
    });
  };

  const handlePreview = (file) => {
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    console.log(fileList.length);
  };

  const errorModal = () => {
    return Modal.error({
      title: "Error",
      content: "Register Error",
    });
  };

  return (
    <div>
      <div className="container-body" id="backgroundMain">
        <div className="purple"></div>
        <div className="medium-blue"></div>
        <div className="light-blue"></div>
        <div className="red"></div>
        <div className="orange"></div>
        <div className="yellow"></div>
        <div className="cyan"></div>
        <div className="light-green"></div>
        <div className="lime"></div>
        <div className="magenta"></div>
        <div className="lightish-red"></div>
        <div className="pink"></div>
      </div>
      <div className="row">
        <div className="card-item-register">
          <img
            src={logo}
            width="100vw"
            alt="alk-logo"
            style={{
              borderRadius: "1000px",
              zIndex: 1,
              position: "relative",
              top: "35pt",
            }}
          />
          <Card
            className="inner-card-register"
            style={{ borderRadius: "15px" }}
            headStyle={{
              textAlign: "center",
              fontSize: "20px",
              backgroundColor: "rgba(0,0,0,0.5) !important",
            }}
            bodyStyle={{ backgroundColor: "transparent" }}
          >
            <FaArrowAltCircleLeft
              className="backicon"
              size={35}
              onClick={() => history.replace("/login")}
            />

            <Form
              {...layout}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={["user", "username"]}
                label="ชื่อผู้ใช้"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อผู้ใช้",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={["user", "firstName"]}
                label="ชื่อจริง"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อจริง",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={["user", "lastName"]}
                label="นามสกุล"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกนามสกุล",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["user", "nickName"]}
                label="ชื่อเล่น"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อเล่น",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["user", "email"]}
                label="อีเมล"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกอีเมล",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>

              <Form.Item
                name={["user", "password"]}
                label="รหัสผ่าน"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกรหัสผ่าน",
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              <Form.Item
                name={["user", "confirm_password"]}
                label="รหัสผ่านอีกครั้ง"
                dependencies={["user", "password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกรหัสผ่าน",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        !value ||
                        getFieldValue(["user", "password"]) === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input type="password" />
              </Form.Item>
              {/* <Form.Item name={['user', 'profileImg']}
                                label="รูปโปรไฟล์"
                            >
                                <Upload
                                    listType="picture-card"
                                    className="upload-list-inline"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleUpload}
                                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                                >

                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">อัพโหลด</div>
                                    </div>
                                </Upload>


                            </Form.Item> */}
              <Form.Item>
                <Button
                  loading={isLoading}
                  block
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  ลงทะเบียน
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}
