import React from "react";
import logo from "../assets/logo.png";

export default function Banner({ children, title, subtitle }) {
  return (
    <div className="banner">
      <img src={logo} width="100%" />
    </div>
  );
}
