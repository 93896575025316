import React, { useEffect, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { Spin, Select } from "antd";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "../styles/problem.css";

const { Option } = Select;

export default function Problem({ match }) {
  const history = useHistory();
  const [language, setLanguage] = useState("cpp");
  const [code, setCode] = useState(`#include <iostream>
#include <algorithm>\n
#define FOR(i,a,b) for(int i=a; i<b; i++)
#define ROF(i,a,b) for(int i=b; i>a; i--)
#define SORT(a,n) sort(a, a+n)
#define SORTR(a,n) sort(a, a+n, greater<int>())

using namespace std;
void solve(int tc)
{
    cout << "Case #" << tc << ": ";
    //Code here
}
int main()
{
    int T;
    cin >> T;
    FOR(i, 1, T+1) {
        solve(i);
    }
    return 0;
}`);

  const [problems, setProblems] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/problem/${match.params.id}`,
          {
            headers: new Headers({
              "x-access-token": localStorage.getItem("token"),
            }),
          }
        ).then((res) => res.json());
        setProblems(response);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [match.params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
    if (value === "python") {
      setCode(`# Sample code to perform I/O:
T = input()            # Reading input from STDIN
for i in range(T):
    arr = input()
        
# Warning: Printing unwanted or ill-formatted data to output will cause the test cases to fail

# Write your code here
        `);
    } else {
      // setCode('Coming soon.....');
      setCode(`#include <iostream>
#include <algorithm>\n
#define FOR(i,a,b) for(int i=a; i<b; i++)
#define ROF(i,a,b) for(int i=b; i>a; i--)
#define SORT(a,n) sort(a, a+n)
#define SORTR(a,n) sort(a, a+n, greater<int>())

using namespace std;
void solve(int tc)
{
    cout << "Case #" << tc << ": ";
    //Code here
}
int main()
{
    int T;
    cin >> T;
    FOR(i, 1, T+1) {
        solve(i);
    }
    return 0;
}`);
    }
  };

  if (!problems) {
    return (
      <center>
        <Spin />
      </center>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        height: "auto",
        minHeight: "80vh",
        padding: "0",
      }}
    >
      <div className="header_problem">
        <FaArrowLeft
          className="backicon"
          size={22}
          onClick={() => history.replace("/grader")}
        />{" "}
        <span style={{ padding: "15px", fontSize: "18pt" }}>
          {" "}
          Problem - {problems.problem_name}
        </span>
      </div>

      <div className="row" style={{ padding: "10px" }}>
        <div
          className="col-sm-12 col-md-7 problem_section"
          style={{ overflowY: "scroll", height: "75vh" }}
        >
          <section>{parse(problems.html_problem)}</section>
          <table className="table-io table table-bordered table-condensed">
            <tbody>{parse(problems.html_sample)}</tbody>
          </table>
        </div>
        <div className="col-sm-12 col-md-5">
          <CodeEditor
            value={code}
            language={language}
            onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            minHeight="75vh"
            style={{
              fontSize: 14,
              backgroundColor: "#f5f5f5",
              overflow: "scroll",
              height: "70vh",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
          <br />
          <Select
            defaultValue="cpp"
            style={{ width: "25%" }}
            onChange={handleLanguageChange}
          >
            <Option value="cpp">C++17 (G++)</Option>
            <Option value="python">Python 3.7</Option>
          </Select>
          <div style={{ float: "right" }}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <button disabled className="btn btn-primary">
                Submit attempt
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
