import React from "react";
import { Form, Input, Button, Card, Modal } from "antd";
import logo from "../assets/logo.png";
import "../styles/backgroundAnimation.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const history = useHistory();

  const layout = {
    labelCol: {
      span: 4,
    },
    // wrapperCol: {
    //     span: 20,
    // },
  };
  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  /* eslint-enable no-template-curly-in-string */

  const sendMail = async (values) => {
    const formData = new FormData();
    formData.append("email", values.user.email);

    await fetch(
      "https://dgwsz9yo81.execute-api.ap-southeast-1.amazonaws.com/api/user/forgotPassword",
      {
        method: "POST",
        body: formData,
      }
    ).then((res) => {
      if (res.status === 200 || res.status === 201) {
        window.location.href = "/login";
      } else {
        errorModal();
      }
    });
  };

  const errorModal = () => {
    return Modal.error({
      title: "Error",
      content: "Register Error",
    });
  };

  return (
    <div>
      <div className="container-body" id="backgroundMain">
        <div className="purple"></div>
        <div className="medium-blue"></div>
        <div className="light-blue"></div>
        <div className="red"></div>
        <div className="orange"></div>
        <div className="yellow"></div>
        <div className="cyan"></div>
        <div className="light-green"></div>
        <div className="lime"></div>
        <div className="magenta"></div>
        <div className="lightish-red"></div>
        <div className="pink"></div>
        <div></div>
      </div>
      <div className="row">
        <div className="card-item-forgot">
          <img
            src={logo}
            width="100vw"
            alt="alk-logo"
            style={{
              borderRadius: "1000px",
              zIndex: 1,
              position: "relative",
              top: "35px",
            }}
          />
          <Card
            className="inner-card-forgot"
            style={{ borderRadius: "15px" }}
            headStyle={{
              textAlign: "center",
              fontSize: "20px",
              backgroundColor: "rgba(0,0,0,0.5) !important",
            }}
            bodyStyle={{ backgroundColor: "transparent" }}
          >
            <FaArrowAltCircleLeft
              className="backicon"
              size={35}
              onClick={() => history.replace("/login")}
            />

            <Form
              {...layout}
              onFinish={sendMail}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={["user", "email"]}
                label="อีเมล"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกอีเมล",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" size="large" htmlType="submit">
                  ส่งอีเมล
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}
