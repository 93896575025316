import React from "react";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Services from "../components/Services";
import {
  FaYoutube,
  FaCameraRetro,
  FaInfoCircle,
  FaCode,
  FaFacebook,
} from "react-icons/fa";

export default function Main() {
  return (
    <>
      <Hero>
        <Banner title="ALKORNITHM" subtitle="Programming Journal" />
      </Hero>
      <Services
        title="Services"
        services={[
          {
            icon: <FaCode />,
            title: "Grader",
            info: "This is an online judge that can be used for conducting or competitive programming.",
          },
          {
            icon: <FaYoutube color="red" />,
            title: "Free YouTube programming courses",
            info: "Alkornithm channel include free programming playlists such as algorithm, project management, etc.",
          },
        ]}
      />

      <Services
        title="Social media"
        services={[
          {
            icon: <FaFacebook color="blue" />,
            title: "Facecbook",
            info: "This is an online judge that can be used for conducting or competitive programming.",
          },
        ]}
      />
    </>
  );
}
