import React from 'react';
import Card from '../components/Card';

const coursesDetail = [
    {
        title: '[2023] Algorithms | อัลกอริทึม',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xhRc4vA8XHt8wtdDFCAKfNi'
    },
    {
        title: 'Operating System | ระบบปฏิบัติการ',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xhUZYkT3uFnS6oBAYhsMTtK'
    },
    {
        title: '[2022] Neural Network | โครงข่ายประสาทเทียม',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xiQYh9A7fQxEf20AbYcr7UV'
    },
    {
        title: '[2022] Database Systems | ระบบฐานข้อมูล',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xiYju9NjDAbMiSFAI1PIXq_'
    },
    {
        title: '[2022] Principle of Programming Language - หลักภาษาโปรแกรม',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xhAFCp809EJIYvz06nVdore',
    },
    {
        title: '[2022] Project Management - การบริหารโครงการ',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xgrTzTPgGYBpPwJOlFFqL4a',
    },
    {
        title: '[2022] Numerical Methods - วิธีการเชิงตัวเลข',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xiCO-itRWUVVyyWOSAFMZ3K',
    },
    {
        title: '[2022] Algorithms - อัลกอริทึม',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xinOKQKIUwTL7i6WlCuRpGf',
    },
    {
        title: '[2022] Machine Learning - การเรียนรู้ของเครื่องคอมพิวเตอร์',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xjq-Bx3DMnuwKjUONesywdS',
    },
    {
        title: 'Principle of Programming Language - หลักภาษาโปรแกรม',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xiYVCtLuRIRLIDnlaxeEaBn'
    },
    {
        title: 'Computer System Security - ความมั่นคงภายในระบบคอมพิวเตอร์',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xgxNIpqkgGSXz0J9cSR1QKc'
    }, {
        title: 'Design & Analysis of Algorithms - การวิเคราะห์และออกแบบขั้นตอนวิธี',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xhGGp86hIAwyUAgI_x0lTSA'
    },
    {
        title: 'Project Management - การบริหารโครงการ',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xh9wrcJVrMAO2tCbmc_W1Zu'
    },
    {
        title: 'Numerical Methods - วิธีการเชิงตัวเลข',
        description: '-',
        url: 'https://www.youtube.com/embed/videoseries?list=PLnTH12URp2xhtCpm4XObqyKWnEnAPeMei'
    },
    
]

export default function Courses() {
    return (
        <div>
            <div className="row gy-5 courses">
                {coursesDetail.map((course, i) => {
                    return <div key={i} className="col-12 col-sm-12 col-md-4">
                        <Card title={course.title} description={course.description} url={course.url} />
                    </div>
                })}

            </div>





        </div>
    )
}
